<div class="top-header no-mobile">
    <div class="container top-header_content">
        
    </div>
</div>
<div class="header no-mobile">
    <div class="container header_content">
        <div class="row">
            <div class="col-3 logo">
                <a routerLink=""><img src="../../assets/images/header/logo-toyosa-equipos.png" class="img-logo"/></a>
            </div>
            <div class="col-9 pl-4 marcas">
                <div class="ml-auto menu">
                    <a href="https://www.hino.bo/"><img src="../../assets/images/header/marcas/hino.png" class="img-marcas"/></a>
                    <a href="https://kubota.com.bo/"><img src="../../assets/images/header/marcas/kubota.png" class="img-marcas"/></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mobile">
    <div id='header' class="header_mobile">
        <div class="menu-fixed">
            <div class="container header-menu_mobile">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <a class="navbar-brand" routerLink="">
                        <img src="../../assets/images/header/logo-toyosa-equipos.png" class="logo-mobile"/>
                    </a>         
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation" (click)="viewMenuPrincipal()">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
            </div>
        </div>
    </div>

    <div class="menu-principal" *ngIf="menuPrincipal">
        <div class="row">
            <div class="col-8 menu-principal_items">
                <div class="row pt-4 pb-4 brand-principal">
                    <a class="col-6 pl-5 navbar-brand_principal" routerLink="">
                        <img src="../../assets/images/header/logo-toyosa-equipos.png" class="logo_principal"/>
                    </a> 
                    <div class="col-6">

                    </div>
                </div>
                <ul class="nav flex-column ">
                    <li class="nav-item">
                        <a href="https://www.hino.bo/" class="nav-link item" ><img src="../../assets/images/header/marcas/mobile-hino.png"/></a>
                    </li>
                    <li class="nav-item">
                        <a href="https://kubota.com.bo/" class="nav-link item" ><img src="../../assets/images/header/marcas/mobile-kubota.png"/></a>
                    </li>
                </ul>
            </div>
            <div class="col-4 menu-principal_back">
                <a (click)="noViewMenuPrincipal()" class="opacity-block"></a>
            </div>
        </div>     
    </div>
</div>
