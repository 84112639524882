<div class="condiciones-uso">
    <div class="container">
        <div>
            <div>
                <h2 class="color-kaizen title">POLITICAS DE PRIVACIDAD</h2>
            </div>
        </div>
        <div>
            <div>
                <div class="text-content">
                    Este sitio web ha sido creado por TOYOSA EQUIPOS LTDA., representante e importador oficial de las marcas SDLG, HINO, KUBOTA, para Bolivia (en adelante TOYOSA EQUIPOS), con la finalidad de informar a los usuarios respecto de los términos y condiciones aplicables al uso del portal web.
                </div>
                <div class="text-content">
                    El acceso y la utilización de este sitio web implican aceptación, sin limitaciones ni reservas de las disposiciones detalladas a continuación. Por consiguiente, el acceso a la página web de TOYOSA EQUIPOS está sometido tanto a estas disposiciones como a la legislación aplicable.
                </div>
                <div class="text-content">
                    La información disponible en este sitio web es independiente a la de la red de concesionarios de las marcas antes citadas, por lo que en ningún caso serán sustituibles entre sí.
                </div>
                <h3 class="reference-concept color-kaizen">1.- ¿Qué son los datos personales?</h3>
                <div class="text-content">
                    Es toda información que pueda identificar a una persona natural o que la hace identificable a través de medios que pueden ser razonablemente utilizados. TOYOSA EQUIPOS podrá recopilar diferentes datos personales indicados en nuestros formularios a través del sitio web como:
                </div>
                <div class="text-content">
                    <ul>
                        <li>Nombres y apellidos</li>
                        <li>Documento de identidad (CI, NIT, Carné de extranjería o Pasaporte)</li>
                        <li>Genero</li>
                        <li>Dirección</li>
                        <li>E-mail</li>
                        <li>Teléfono</li>
                    </ul>
                </div>
                <div class="text-content">
                    Al completar tus datos personales requeridos en los formularios de la web, estás garantizando la exactitud veracidad y actualización de la información proporcionada.
                </div>
                <div class="text-content">
                    Además, estas aceptando que a suministrar tu información otorgas el consentimiento expreso para que TOYOSA EQUIPOS trate tus datos personales según las finalidades descritas en la presente política.
                </div>
                <div class="text-content">
                    En caso de que ingreses datos personales incorrectos, TOYOSA EQUIPOS no podrá ponerse en contacto contigo.
                </div>
                <h3 class="reference-concept color-kaizen">2.- Consentimiento</h3>
                <div class="text-content">
                    Al momento que completes tus datos personales, estarás otorgando tu consentimiento libre, previo, expreso, informado e inequívoco para que TOYOSA EQUIPOS trate tus datos personales según las finalidades descritas en el presente documento.
                </div>
                <div class="text-content">
                    Asimismo, al brindar los datos requeridos en los formularios del sitio web- ya sea para solicitar cotización de un vehículo de interés o para plantearnos algunas consultas o queja- estas garantizando la exactitud, veracidad y actualización de la información proporcionada.
                </div>
                <h3 class="reference-concept color-kaizen">3.- Transferencia de información a terceros</h3>
                <div class="text-content">
                    Teniendo en cuenta las finalidades de recopilación, TOYOSA EQUIPOS podrá transferir tus datos personales a empresas vinculadas y/o socios estratégicos con similar actividad, dentro y fuera del país, así como a su red de concesionarios autorizados, en tanto no revoques la presente autorización.
                </div>
                <h3 class="reference-concept color-kaizen">4.- Tratamiento de datos personales de menores de edad</h3>
                <div class="text-content">
                    Comprendemos la importancia de proteger la privacidad de los menores de edad, especialmente en un sitio web; por esta razón de no contar con el consentimiento previo de sus padres y/o tutores legales, no llevaremos a cabo el tratamiento de datos personales relativos a menores de 18 años. Si detectamos que los datos personales recopilados corresponden a un menor de edad, se adoptaran las medidas oportunas a su eliminación.
                </div>
                <h3 class="reference-concept color-kaizen">5.- Medidas de seguridad adoptadas para la protección de los datos personales</h3>
                <div class="text-content">
                    ¿Qué medidas de seguridad ha tomado TOYOSA EQUIPOS?
                </div>
                <div class="text-content">
                    Hemos adoptado las medidas exigidas por el ordenamiento jurídico y nos comprometemos a tratar los datos personales como información confidencial.
                </div>
                <div class="text-content">
                    TOYOSA EQUIPOS no se hace responsable sobre el riesgo de pérdida de información de datos personales, cuando el usuario realiza la transferencia de información a los servidores de TOYOSA EQUIPOS a través de la computadora o dispositivo móvil.
                </div>
                <h3 class="reference-concept color-kaizen">6.- Empleo de cookies</h3>
                <div class="text-content">
                    TOYOSA EQUIPOS te informa del envío de cookies a las computadoras o dispositivos móviles cada vez que visites este sitio web, o hagas uso de alguna de sus aplicaciones.
                </div>
                <div class="text-content">
                    Cada vez que visites este sitio web, o hagas uso de alguna de sus aplicaciones, te informaremos del envío de cookies a las computadoras dispositivos móviles. TOYOSA EQUIPOS otorgará a la información obtenida por medio de cookies a las computadoras o dispositivos móviles. TOYOSA EQUIPOS otorgará a la información obtenida por medio de cookies el mismo tratamiento que le brinda a la información de datos personales.
                </div>
                <div class="text-content">
                    Las finalidades de la recopilación de información a través de cookies son las mismas por las que se recolecta la información personal. La información recopilada a través de las cookies será transferida a terceros dentro de los mismos límites que la información personal. TOYOSA EQUIPOS mantendrá esta información segura de acuerdo a ley y otorgará también carácter confidencial. Tú como usuario cuentas con los mismos derechos sobre la información recogida por medio de cookies que aquellos datos personales otorgados.
                </div>
                <div class="text-content">
                    Como usuario te encuentras en la capacidad de deshabilitar la mayoría de cookies que son enviadas a las computadoras y dispositivos móviles por medio del cambio de las configuraciones predispuestas para sus navegadores y sistemas operativos. El deshabilitar las cookies puede tener como consecuencia que TOYOSA EQUIPOS no ofrezca contenido personalizado a sus usuarios.
                </div>
                <h3 class="reference-concept color-kaizen">7.- Redes sociales</h3>
                <div class="text-content">
                    TOYOSA EQUIPOS no se hace responsable por la información contenida en las redes sociales a las que puedas acceder por medio de la página web de TOYOSA EQUIPOS. Las redes sociales de las que participan tanto TOYOSA EQUIPOS como tú cuentan con sus propias políticas de seguridad, a las que deberán estar sujeto.
                </div>
                <div class="text-content">
                    Asimismo, TOYOSA EQUIPOS se libera de toda responsabilidad que pueda ocasionar el incorrecto funcionamiento y/o el inadecuado uso de las redes sociales, la falsedad del contenido y la ilicitud de la forma en que este fue obtenido, así como de los daños y perjuicios que se pudieran generar por las publicaciones en estas redes.
                </div>
            </div>
        </div>
    </div>
</div>
